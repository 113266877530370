




































import { defineComponent, computed } from '@nuxtjs/composition-api';
import collectionGetters from '~/modules/catalog/category/getters/collectionGetters';
import { SfButton, SfVideo } from '~/components';

export default defineComponent({
  components: {
    SfVideo,
    SfButton,
    CollectionLayout_pillows: () => import('~/modules/catalog/category/components/collections/internal/_collectionLayout_pillows.vue'),
    CollectionLayout_bedding: () => import('~/modules/catalog/category/components/collections/internal/_collectionLayout_bedding.vue'),
    CollectionLayout_dinning: () => import('~/modules/catalog/category/components/collections/internal/_collectionLayout_dinning.vue'),
    CollectionLayout_kids: () => import('~/modules/catalog/category/components/collections/internal/_collectionLayout_kids.vue'),
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  emits: ['scroll'],
  setup(props) {
    const type = computed(() => collectionGetters.getType(props.content));
    const layoutName = computed(() => `CollectionLayout_${collectionGetters.getType(props.content)}`);

    return {
      type,
      layoutName,
      ...collectionGetters
    };
  }
});
