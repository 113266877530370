import { CategoryCollection } from '~/modules/GraphQL/types';

const collectionGetters = {

  getHeroTitle(content) {
    return content?.title ?? null;
  },

  getHeroSubtitle(content) {
    return content?.subtitle ?? null;
  },

  getButtonText(content) {
    return content?.button_text ?? null;
  },

  hasVideo(content) {
    return Boolean(content?.video_desktop_url || content?.video_mobile_url);
  },

  getMobileVideo(content) {
    return content?.video_mobile_url ?? null;
  },

  getMobileVideoPoster(content) {
    return content?.video_mobile_poster_url ?? null;
  },


  getDesktopVideo(content) {
    return content?.video_desktop_url ?? null;
  },

  getDesktopVideoPoster(content) {
    return content?.video_desktop_poster_url ?? null;
  },

  getMobileHeroImage(content) {
    return content?.hero_image_mobile_url ?? null;
  },

  getDesktopHeroImage(content) {
    return content?.hero_image_desktop_url ?? null;
  },

  getMainContent(content) {
    return content?.content ?? null;
  },

  getMobileContentImage(content) {
    return content?.image_mobile_url ?? null;
  },

  getDesktopContentImage(content) {
    return content?.image_desktop_url ?? null;
  },

  getLayoutId(content) {
    return content?.layout_id ?? null;
  },

  getType(content) {
    return content?.type ?? null;
  },

};

export default collectionGetters;
